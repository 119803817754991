import ApiService from "@/core/services/api.service.js";

const reviewService = {
    getReviewList(param) {
        return  new Promise((resolve, reject) => {
          
          var paramStr = ''
          if(param.page != undefined){
            paramStr = '?page='+(param.page+1)
          }else{
            paramStr = '?'
          }
          for (const key in param.filters) {
            if(param.filters[key] != null)
              paramStr += '&' + key + '=' + param.filters[key]
          }
          
          ApiService.get("api/admin/review/list"+paramStr)
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              resolve("");
              reject(error)
            });
        });
    },
    getReviewById(id) {
      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/review/detail/"+id)
          .then(function(response) {
            if (response.data.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },   
  updateStatus(data) {
    return  new Promise((resolve, reject) => {
      ApiService.post("api/admin/review/update/"+data.id,data)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
},
}

export default reviewService;