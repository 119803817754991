<template>
  <b-card no-body class="card-custom">
    <template #header>
      <div class="card-title">
        <h3 class="card-label">Orders</h3>
      </div>
    </template>

    <div class="card card-custom gutter-b card-stretch card-shadowless">
      <!-- User Interface controls -->

      <div class="card-body pt-0">
        <DataTable ref="dt" :value="items" dataKey="id" :lazy="true" :paginator="true" :rows="rowCount" class="mt-0"
          :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products">

          <b-card-header class="p-4">
            <b-row>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                    <b-form-select v-model="filters.order_status" placeholder="Order Status"
                      :options="statusList" @change="onFilter()"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                    <b-form-select v-model="filters.rating" placeholder="Payment Status"
                      :options="rating" @change="onFilter()"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-right w-100">
                  
                    <b-form-input v-model="filters.name" placeholder="Search ID, Name.." class="py-2"
                      @keydown.enter="onFilter()" />
                      <i class="las la-search"></i>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-header>

          <template #empty>
            <div class="text-center text-muted py-5">Products not found.</div>
          </template>


          <Column field="sl" header="#" class="SL-n">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column>
          <Column field="Product Name" header="Product Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.product.name }}
            </template>
          </Column>
          <Column field="model" header="Model" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.product.model }}
            </template>
          </Column>
          
          <Column field="customer" header="Customer" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.customerInfo.name }}
            </template>
          </Column>

          <Column field="rating" header="Rating" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.rating }}
            </template>
          </Column>

           <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.status }}
            </template>
          </Column>

          <Column field="action" header="Action" :sortable="true">
            <template #body="slotProps">
              <b-button  size="sm" pill  :to="`/review-status-form/${slotProps.data.id}`" class="py-2 px-4 border bg-white bg-hover-primary">Status</b-button>
              <!-- <b-button  size="sm" pill  :to="`/review-detail/${slotProps.data.id}`" class="py-2 px-4 border bg-white bg-hover-primary">Detail</b-button> -->
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import ReviewService from "@/core/services/api/review";

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown
  },
  data() {
    return {
      statusList: [
        { value: null, text: 'All' }
      ],
      rating: [
        { value: null, text: 'All' },
        { text: "Created", value: "created" },
        { text: "Success", value: "success" },
        { text: "Failed", value: "failed" }
      ],
      items: [],
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      loading: false,
      totalRecords: 0,
      rowCount: 1,
      lazyParams: {},
    };
  },
  mounted() {
    this.loading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };

    this.$store.dispatch(SET_BREADCRUMB, [
    {
          title: "Dashboard",
          route: "/dashboard",
        },
        {
          title: "Review",
          route: "/review",
        },
    ]);
    this.getReviewList();
  },
  computed: {
    filters: function () {
      var filters = {
        name: null,
        model: null,
        rating: null,
      }
      return filters
    }
  },
  methods: {
    async getReviewList() {
      var response = await ReviewService.getReviewList(this.lazyParams);
      if (response.data != undefined) {
        this.items = response.data;
        this.totalRecords = response.total;
        this.rowCount = response.per_page;
      } else {
        this.items = [];
        this.totalRecords = 0;
        this.rowCount = 1;
      }
      this.loading = false;
    },
    onPage(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getReviewList();
    },
    onSort(event) {
      this.loading = true;
      this.lazyParams = event;
      this.getReviewList();
    },
    onFilter() {
      this.loading = true;
      this.lazyParams.filters = this.filters;
      this.getReviewList();
    },
  },

};
</script>

<style lang="scss">
.p-datatable {
  .p-datatable-wrapper {
    .p-datatable-table {
      tr {
        td:last-child {
          width: 100px
        }
      }
    }
  }
}
</style>